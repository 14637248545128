import React, { Component } from "react";
import { FaFacebookF , FaLink, FaLinkedinIn , FaTwitter } from "react-icons/fa";

let TeamContent = [
    {
        images: '03',
        title: 'Dimitrios Damopoulos, PhD',
        designation: 'CySecurity | AI | Blockchain',
        socialNetwork: [
            {   webname: 'Website',
                icon: <FaLink  />,
                url: 'https://www.damopoulos.me'
            },
          
        ]
    },
    {
        images: '01',
        title: 'Ryan Grissett',
        designation: 'AI | Blockchain',
        socialNetwork: [

          
        ]
    },
    {
        images: '05',
        title: 'Sevgi Sarıkaya',
        designation: 'Full-Stack',
        socialNetwork: [
           
        ]
    },
    {
        images: '08',
        title: 'Loukas Protopapas, PhD',
        designation: 'Blockchain',
        socialNetwork: [
            
        ]
    },
    {
        images: '04',
        title: 'Lazaros Vrysis, PhD',
        designation: 'AI',
        socialNetwork: [
           
        ]
    },
    {
        images: '06',
        title: 'Michy Loo, PhD',
        designation: 'Instructional Design',
        socialNetwork: [
            
        ]
    },
    {
        images: '09',
        title: 'Stephanie Garay',
        designation: 'Instructional Design',
        socialNetwork: [
            
        ]
    },
    {
        images: '07',
        title: 'Lisa Garay',
        designation: 'Virtual Assistant',
        socialNetwork: [
            
        ]
    },
    {
        images: '02',
        title: 'Kostas Trantopoulos',
        designation: 'Legal',
        socialNetwork: [
            
        ]
    }
    
];


class Team extends Component{
    render(){
        const {column} = this.props;
        return(
            <React.Fragment>
                {TeamContent.map((value , i ) => (
                    <div className={`${column}`} key={i}>
                        <div className="team">
                            <div className="thumbnail">
                                <img src={`/assets/images/team/team-${value.images}.jpg`} alt="Blog Images"/>
                            </div>
                            <div className="content">
                                <h4 className="title">{value.title}</h4>
                                <p className="designation">{value.designation}</p>

                            </div>
                            <ul className="social-icon" >
                                {value.socialNetwork.map((social, index) =>
                                    <li key={index}><a href={`${social.url}`}>{social.icon} www.damopoulos.me </a></li>
                                )}
                            </ul>
                        </div>
                    </div>
                ))}
            </React.Fragment>
        )
    }
}
export default Team;
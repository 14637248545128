import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
import ModalVideo from 'react-modal-video';
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";


class ServiceRD extends Component{
    constructor () {
        super()
        this.state = {
          isOpen: false
        }
        this.openModal = this.openModal.bind(this)
    }
    openModal () {
        this.setState({isOpen: true})
    }
    render(){
        return(
            <React.Fragment>
                
                {/* Start Pagehelmet  */}
                <PageHelmet pageTitle='Research and Design' />
                {/* End Pagehelmet  */}

                <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />

                {/* Start Breadcrump Area */}
                <div className="rn-page-title-area pt--120 pb--190 bg_image bg_image--5"  data-black-overlay="5">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="rn-page-title text-center pt--100">
                                    <h2 className="title theme-gradient">Research & Design </h2>
                                    <p>Building the Future of AI & Blockchain Technology </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Breadcrump Area */}

                {/* Start Page Wrapper */}
                <div className="rn-service-details ptb--120 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="service-details-inner">
                                    <div className="inner">
                                        {/* Start Single Area */}
                                        <div className="row sercice-details-content pb--15 align-items-center">
                                            <div className="col-lg-5 col-5">
                                                <div className="thumb">
                                                <img className="w-10" src="/assets/images/service/service-04.png" alt="Service Images"/>
                                                
                                                </div>
                                            </div>
                                        
                                            <div className="col-lg-6 col-12">
                                                <div className="details mt_md--30 mt_sm--30">
                                                    
                                                <h4 className="title">AI Trustworthiness</h4>
                                                    <p> AI trustworthiness refers to the degree to which an AI system can be relied upon to operate as intended and to produce accurate and reliable results. Trustworthy AI systems are designed and implemented in ways that ensure they operate safely, securely, and ethically.   </p>
                                                   
                                                    <h4 className="title">AI explainability</h4>
                                                    <ul className="liststyle">
                                                        <li> AI explainability services help organizations understand how AI systems make decisions by providing insights into the algorithms and data used. This can help improve transparency and accountability, which are important for building trust in AI systems. </li>
                                                    </ul>

                                                    <h4 className="title">AI Governance</h4>
                                                    <ul className="liststyle">
                                                        <li> AI governance services provide a framework for ensuring that AI systems are designed and implemented in ways that align with ethical and legal considerations. This can include developing policies and guidelines for the use of AI, as well as establishing oversight and review mechanisms to ensure compliance. </li>
                                                    </ul>

                                        
                                                    <h4 className="title">AI testing and validation</h4>
                                                    <ul className="liststyle">
                                                        <li> AI testing and validation services help ensure that AI systems are functioning as intended and producing accurate and reliable results. This can include testing for bias and other errors, as well as validating the performance of AI models against established benchmarks and standards. </li>
                                                    </ul>

                                                    <h4 className="title">AI security</h4>
                                                    <ul className="liststyle">
                                                        <li> AI security services help protect AI systems from cyber threats and other security risks. This can include implementing encryption and access controls, as well as monitoring for suspicious activity and responding to security incidents. </li>
                                                    </ul>

                                                    <h4 className="title">AI Ethics & Bias</h4>
                                                    <ul className="liststyle">
                                                        <li> AI Ethics and Bias are critical considerations in the development and deployment of artificial intelligence systems to ensure fairness and avoid harmful impacts on society. </li>
                                                    </ul>

                                                    <h4 className="title">AI compliance </h4>
                                                    <ul className="liststyle">
                                                        <li> AI compliance services help ensure that AI systems comply with relevant regulations and standards, such as GDPR, HIPAA, and ISO 27001. This can involve assessing the regulatory landscape and designing AI systems to meet specific compliance requirements. </li>
                                                    </ul>

                                                </div>
                                            </div>                                        


                                            <div className="col-lg-6 col-12">
                                                <div className="details mt_md--30 mt_sm--30">
                                                <img className="w-10" src="/assets/images/service/service-03.png" alt="Service Images"/>
  
                                                <h4 className="title">Blockchain Dapps</h4>
                                                    <p> AI trustworthiness refers to the degree to which an AI system can be relied upon to operate as intended and to produce accurate and reliable results. Trustworthy AI systems are designed and implemented in ways that ensure they operate safely, securely, and ethically.   </p>
                                                   
                                                    <h4 className="title">Ethereum Blockchains</h4>
                                                    <ul className="liststyle">
                                                        <li> Blockchain technology uses advanced cryptographic techniques to ensure that transactions are secure and tamper-proof. This can help to prevent fraud and reduce the risk of data breaches. </li>
                                                    </ul>

                                                    <h4 className="title">Smart Contracts </h4>
                                                    <ul className="liststyle">
                                                        <li> Smart contracts are self-executing contracts that are built on blockchain technology. They can automate business processes and eliminate the need for intermediaries, reducing costs and improving efficiency. </li>
                                                    </ul>

                                                    <h4 className="title">Web 3</h4>
                                                    <ul className="liststyle">
                                                        <li>  Blockchains are decentralized systems, meaning that there is no central authority or single point of failure. This can help to increase transparency and reduce costs by eliminating intermediaries and third-party service providers. </li>
                                                    </ul>

                                                    <h4 className="title">Blockchain Data Management</h4>
                                                    <ul className="liststyle">
                                                        <li> Blockchain technology can be used to securely store and manage large amounts of data. This can help to streamline data management processes, reduce costs, and improve data security. </li>
                                                    </ul>


                                                </div>
                                            </div>


                                        </div>
                                        {/* End Single Area */}

                                       

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Page Wrapper */}
                
                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}
                

            </React.Fragment>
        )
    }
}
export default ServiceRD;
import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
import ModalVideo from 'react-modal-video';
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";


class ServiceAI extends Component{
    constructor () {
        super()
        this.state = {
          isOpen: false
        }
        this.openModal = this.openModal.bind(this)
    }
    openModal () {
        this.setState({isOpen: true})
    }
    render(){
        return(
            <React.Fragment>
                
                {/* Start Pagehelmet  */}
                <PageHelmet pageTitle='AI & Blockchain ' />
                {/* End Pagehelmet  */}

                <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />

                {/* Start Breadcrump Area */}
                <div className="rn-page-title-area pt--120 pb--190 bg_image bg_image--5"  data-black-overlay="5">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="rn-page-title text-center pt--100">
                                    <h2 className="title theme-gradient">AI & Blockchain Systems</h2>
                                    <p>Systems build for Quantom realm </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Breadcrump Area */}


        {/* Start Page Wrapper */}
        <div className="rn-service-details ptb--120 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="service-details-inner">
                                    <div className="inner">
                                        {/* Start Single Area */}
                                        <div className="row sercice-details-content pb--15 align-items-center">
                                            <div className="col-lg-5 col-5">
                                                <div className="thumb">
                                                <img className="w-10" src="/assets/images/service/service-04.png" alt="Service Images"/>
                                                <p>  </p>

                                                </div>
                                            </div>
                                            
                                            <div className="col-lg-6 col-12">
                                                
                                                <div className="details mt_md--30 mt_sm--30">


                                                <h4 className="title">AI Modelling</h4>
                                                    <ul className="liststyle">
                                                        <li>AI is transforming the field of human resources by providing new and innovative ways to identify, hire, and manage talent. With the ability to analyze vast amounts of data, AI can assist with talent acquisition by identifying potential candidates who possess the desired skills and experience. AI can also streamline the hiring process, using natural language processing to screen resumes and conduct initial interviews. Additionally, AI can help improve employee engagement by providing personalized feedback and support, as well as identifying areas for improvement in performance. AI-powered analytics can also help HR professionals identify potential issues in the workplace, such as high turnover rates, low employee satisfaction, or productivity issues. By leveraging the power of AI in human resources, organizations can improve their talent management strategies and create a more engaged, productive, and satisfied workforce. </li>
                                                    </ul>

                                                     
                                               
                                                    <h4 className="title">Machine Learning & Deep Learning</h4>
                                                    <ul className="liststyle">
                                                        <li> Machine and deep learning are subsets of artificial intelligence that enable computer systems to learn and improve from experience without being explicitly programmed.</li>
                                                    </ul>


                                                    <h4 className="title">Anomaly Detection</h4>
                                                    <ul className="liststyle">
                                                        <li>A process of identifying rare or unusual events or data points that deviate significantly from normal patterns, typically used in fraud detection, network security, and predictive maintenance.</li>
                                                    </ul>

                                                    <h4 className="title">Predictive Maintenance</h4>
                                                    <ul className="liststyle">
                                                        <li>A process of using data analysis and machine learning techniques to predict when equipment or machines are likely to fail, allowing for proactive maintenance and avoiding downtime and costly repairs.</li>
                                                    </ul>

                                                    <h4 className="title">Natural Language Processing (NLP)</h4>
                                                    <ul className="liststyle">
                                                        <li>A branch of artificial intelligence that focuses on enabling machines to understand, interpret, and respond to human language, including speech recognition, language translation, and sentiment analysis.</li>
                                                    </ul>

                                                </div>
                                            </div>
                                            
                                            
                                            <div className="col-lg-6 col-12">
                                                <div className="details mt_md--30 mt_sm--30">

                                                   <h4 className="title">AI Human Resources</h4>
                                                    <ul className="liststyle">
                                                    <li>AI is transforming the field of human resources by providing new and innovative ways to identify, hire, and manage talent. With the ability to analyze vast amounts of data, AI can assist with talent acquisition by identifying potential candidates who possess the desired skills and experience. AI can also streamline the hiring process, using natural language processing to screen resumes and conduct initial interviews. Additionally, AI can help improve employee engagement by providing personalized feedback and support, as well as identifying areas for improvement in performance. AI-powered analytics can also help HR professionals identify potential issues in the workplace, such as high turnover rates, low employee satisfaction, or productivity issues. By leveraging the power of AI in human resources, organizations can improve their talent management strategies and create a more engaged, productive, and satisfied workforce. </li>
                                                    </ul>

                                                    <h4 className="title">Employee Productivity</h4>
                                                    <ul className="liststyle">
                                                        <li> AI-powered employee productivity tools are transforming the workplace by providing new ways to track, monitor, and optimize employee performance. With the ability to analyze vast amounts of data, AI can identify patterns and insights related to employee productivity, such as the amount of time spent on specific tasks or the frequency of breaks. This information can be used to develop personalized productivity plans for individual employees, identifying areas for improvement and providing targeted feedback. Additionally, AI can assist with time management by providing automatic reminders, prioritizing tasks, and allocating workloads based on skillsets and availability. AI-powered tools can also assist with training and development, using machine learning algorithms to identify skills gaps and provide targeted training plans. By leveraging the power of AI in employee productivity, organizations can improve their overall efficiency, increase employee engagement, and ultimately drive better business results.</li>
                                                    </ul>

                                                    <h4 className="title">Predictive Analytics</h4>
                                                    <ul className="liststyle">
                                                        <li> AI-powered predictive maintenance is transforming the way organizations maintain their equipment and machinery. With the ability to analyze vast amounts of data, AI can identify patterns and anomalies that may indicate potential equipment failures, allowing organizations to take proactive steps to prevent downtime and costly repairs. Predictive maintenance uses machine learning algorithms to monitor equipment data, such as temperature, vibration, and pressure, and identify potential issues before they occur. This allows maintenance teams to prioritize repairs based on the level of risk and urgency, reducing unplanned downtime and improving operational efficiency. Predictive maintenance can also help organizations reduce maintenance costs, as they can proactively replace or repair parts before they fail, reducing the need for expensive emergency repairs. Additionally, AI-powered predictive maintenance can help organizations identify areas for improvement in their maintenance practices, optimizing their maintenance schedules and improving overall equipment reliability. By leveraging the power of AI in predictive maintenance, organizations can improve their operational efficiency, reduce costs, and ultimately drive better business outcomes.</li>
                                                    </ul>

                                                </div>
                                            </div>

                                        </div>
                                        {/* End Single Area */}






                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Page Wrapper */}
                
                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}
                

            </React.Fragment>
        )
    }
}
export default ServiceAI;
import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
import ModalVideo from 'react-modal-video';
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";


class ServiceTansformation extends Component{
    constructor () {
        super()
        this.state = {
          isOpen: false
        }
        this.openModal = this.openModal.bind(this)
    }
    openModal () {
        this.setState({isOpen: true})
    }
    render(){
        return(
            <React.Fragment>
                
                {/* Start Pagehelmet  */}
                <PageHelmet pageTitle=' Transforamation' />
                {/* End Pagehelmet  */}

                <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />

                {/* Start Breadcrump Area */}
                <div className="rn-page-title-area pt--120 pb--190 bg_image bg_image--5"  data-black-overlay="5">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="rn-page-title text-center pt--100">
                                    <h2 className="title theme-gradient">AI & Blockchain Business Transformation</h2>
                                    <p>We are the key to an Inteligent Future  </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Breadcrump Area */}

                {/* Start Page Wrapper */}
                <div className="rn-service-details ptb--120 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="service-details-inner">
                                    <div className="inner">
                                        {/* Start Single Area */}
                                        <div className="row sercice-details-content pb--15 align-items-center">
                                            <div className="col-lg-5 col-5">
                                                <div className="thumb">
                                                <img className="w-10" src="/assets/images/service/service-02.png" alt="Service Images"/>
                                            
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-12">
                                                <div className="details mt_md--30 mt_sm--30">
                                                    
                                                <h4 className="title">AI & Blockchain Business Transformation</h4>
                                                <p> In today's rapidly evolving technological landscape, staying ahead of the curve is crucial for any organization looking to thrive in the competitive market. Two of the most promising technologies that have emerged in recent times are artificial intelligence (AI) and blockchain. </p>

<p>As businesses increasingly rely on technology to drive growth and success, building comprehensive ecosystems that encompass a broad range of services and tools has become a critical priority. In today's complex technological landscape, organizations require expert guidance to help them navigate the intricacies of designing and developing customized experience portfolios that align with their unique needs and objectives. That's where our team of experienced experts comes in.</p>

<p>At our consulting firm, we excel at building comprehensive ecosystems for our clients that incorporate a range of services and tools. We work closely with our clients to gain a deep understanding of their business and unique requirements. Based on this understanding, we design and develop customized portfolios that leverage the latest technologies and platforms to meet our clients' needs effectively.</p>

<p>We recognize that building robust ecosystems requires more than just technology. It also involves structuring critical relationships, platforms, apps, programming tools, and cutting-edge technologies like AI and blockchain. Our expertise in these areas allows us to provide our clients with the most innovative solutions and ensure that they have access to the latest tools and services.</p>

<p>In addition to technology, we also recognize the importance of training and educational materials in helping our clients maximize the potential of their ecosystems. That's why we leverage our deep industry knowledge and expertise in instruction design support to ensure that our clients receive the highest quality training and educational materials.</p>

<p>Our commitment to staying up-to-date with the latest trends and emerging technologies ensures that our clients receive the most effective and innovative solutions. We pride ourselves on providing a comprehensive suite of services that cover all aspects of building a successful ecosystem, from strategy development to implementation and ongoing support.</p>

<p>In conclusion, building a comprehensive ecosystem is critical to achieving success in today's competitive market. Our team of experienced experts provides the guidance and support necessary to help organizations design and develop customized portfolios that align with their unique needs and objectives. By leveraging our deep industry knowledge and expertise in the latest technologies and platforms, we help our clients achieve their goals and realize their vision for success.</p>
                                                </div>
                                            </div>
                                        </div>
                                        {/* End Single Area */}

                                       

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Page Wrapper */}
                
                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}
                

            </React.Fragment>
        )
    }
}
export default ServiceTansformation;
import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
import ModalVideo from 'react-modal-video';
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";


class ServiceConsulting extends Component{
    constructor () {
        super()
        this.state = {
          isOpen: false
        }
        this.openModal = this.openModal.bind(this)
    }
    openModal () {
        this.setState({isOpen: true})
    }
    render(){
        return(
            <React.Fragment>
                
                {/* Start Pagehelmet  */}
                <PageHelmet pageTitle='Consulting services for AI and Blockchain ' />
                {/* End Pagehelmet  */}

                <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />

                {/* Start Breadcrump Area */}
                <div className="rn-page-title-area pt--120 pb--190 bg_image bg_image--5"  data-black-overlay="5">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="rn-page-title text-center pt--100">
                                    <h2 className="title theme-gradient">Consulting  </h2>
                                    <p>We enchance your buisness with AI and Blockchain</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Breadcrump Area */}

                {/* Start Page Wrapper */}
                <div className="rn-service-details ptb--120 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="service-details-inner">
                                    <div className="inner">
                                        {/* Start Single Area */}
                                        <div className="row sercice-details-content pb--15 align-items-center">
                                            <div className="col-lg-5 col-5">
                                                <div className="thumb">
                                                <img className="w-10" src="/assets/images/service/service-06.png" alt="Service Images"/>
                                                <p>  </p>

                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-12">
                                                <div className="details mt_md--30 mt_sm--30">
                                                    
                                                <h4 className="title">Consultation </h4>
                                                <p> In today's rapidly evolving technological landscape, staying ahead of the curve is crucial for any organization looking to thrive in the competitive market. Two of the most promising technologies that have emerged in recent times are artificial intelligence (AI) and blockchain. </p>

<p> While AI has revolutionized the way we interact with machines, blockchain has created new avenues for secure and transparent data sharing. However, despite their immense potential, implementing and utilizing these technologies can be a daunting task, and that's where consulting services for AI and blockchain come in.</p>

<p> Consulting services for AI and blockchain can provide organizations with expert guidance on navigating the complex landscape of these technologies. These services can help organizations develop a roadmap for the development and implementation of AI systems, including strategy development, technology selection, and project management. </p>

<p> AI consulting services can also help organizations address ethical and regulatory considerations, such as bias and privacy, to ensure that their AI systems are not only effective but also ethical and compliant with regulations.</p>

<p> Similarly, blockchain consulting services can help organizations design and implement blockchain-based systems. These services can guide organizations through the technology selection process, architecture design, and smart contract development. </p>

<p> Blockchain consulting services can also assist organizations in identifying potential applications of blockchain in their business operations and develop strategies for leveraging this technology to gain a competitive edge.</p>

<p> In both AI and blockchain, consulting services can help organizations make informed decisions that align with their goals and objectives. The consulting services can provide valuable insights and recommendations based on their expertise and experience in the field. With their guidance, organizations can develop a comprehensive understanding of the technologies and their potential applications, which can help them make better decisions. </p>

<p> In conclusion, consulting services for AI and blockchain can be a game-changer for organizations looking to stay ahead of the curve in these emerging technology areas. These services can provide expert guidance on the development and implementation of AI and blockchain systems, as well as help organizations address ethical and regulatory considerations. </p>

<p> With the help of consulting services, organizations can unlock the full potential of these technologies and gain a competitive advantage in their respective industries.</p>
                                                    
                                                </div>
                                            </div>
                                        </div>
                                        {/* End Single Area */}

                                       

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Page Wrapper */}
                
                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}
                

            </React.Fragment>
        )
    }
}
export default ServiceConsulting;
import React ,{ Component }from "react";
import { FiCast , FiLayers , FiUsers , FiMonitor } from "react-icons/fi";

const ServiceList = [
    {
        icon: <FiUsers />,
        title: 'Consulting',
        description: 'Consulting services for AI and Blockchain can help organizations navigate the complex landscape of these rapidly evolving technologies.',
        details: '/ServiceConsulting'
    },
    {
        icon: <FiMonitor />,
        title: 'Business transformation: Services & Ecosystems',
        description: 'We transform your businees creating tailored ecosystems for clients, offering various services and tools, including portfolio design and development, and assistance with platforms, apps, and programming.',
        details: '/serviceTransformation'
    },
    {
        icon: <FiLayers />,
        title: 'AI & Blockchain ',
        description: 'AI and Blockchain technologies are two of the most transformative technologies of this decade, and their combination can bring about significant changes in the way businesses operate.',
        details: '/ServiceAI'
    },
    { 
        icon: <FiUsers />,
        title: 'Education & Instructional Design ',
        description: 'To ensure effective use of evolving technologies like AI and blockchain, education and instructional design play a crucial role in providing tailored training materials to bridge the gap between technology and users.',
        details: '/serviceEducation'
    },
    {
        icon: <FiLayers />,
        title: 'Research & Design',
        description: 'Research in AI and Blockchain technology is rapidly advancing, with new developments and applications being explored in various fields such as finance, healthcare, and cybersecurity.',
        details: '/serviceRD'
    },
    { 
        icon: <FiMonitor />,
        title: 'USA & EU RESEARCH PROJECTS',
        description: 'We contribute to national, USA and European research projects, including Horizon Europe and Erasmus+, NSF.',
        details: '/serviceGrants'
    }
]


class ServiceThree extends Component{
    render(){
        const {column } = this.props;
        const ServiceContent = ServiceList.slice(0 , this.props.item);
        
        return(
            <React.Fragment>
                <div className="row">
                    {ServiceContent.map( (val , i) => (
                        <div className={`${column}`} key={i}>
                            <a href={val.details}>
                                <div className="service service__style--2">
                                    <div className="icon">
                                        {val.icon}
                                    </div>
                                    <div className="content">
                                        <h3 className="title">{val.title}</h3>
                                        <p>{val.description}</p>
                                    </div>
                                </div>
                            </a>
                        </div>
                    ))}
                </div>
            </React.Fragment>
        )
    }
}
export default ServiceThree;
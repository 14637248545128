import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
import ModalVideo from 'react-modal-video';
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";


class ServiceGrants extends Component{
    constructor () {
        super()
        this.state = {
          isOpen: false
        }
        this.openModal = this.openModal.bind(this)
    }
    openModal () {
        this.setState({isOpen: true})
    }
    render(){
        return(
            <React.Fragment>
                
                {/* Start Pagehelmet  */}
                <PageHelmet pageTitle='Research Grants' />
                {/* End Pagehelmet  */}

                <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />

                {/* Start Breadcrump Area */}
                <div className="rn-page-title-area pt--120 pb--190 bg_image bg_image--5"  data-black-overlay="5">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="rn-page-title text-center pt--100">
                                    <h2 className="title theme-gradient">Research Grants</h2>
                                    <p>We innovate in EU and USA Research</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Breadcrump Area */}

                {/* Start Page Wrapper */}
                <div className="rn-service-details ptb--120 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="service-details-inner">
                                    <div className="inner">
                                        {/* Start Single Area */}
                                        <div className="row sercice-details-content pb--15 align-items-center">
                                            <div className="col-lg-5 col-5">
                                                <div className="thumb">
                                                <img className="w-10" src="/assets/images/service/service-05.png" alt="Service Images"/>

                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-12">
                                                <div className="details mt_md--30 mt_sm--30">
                                                    
                                                <h4 className="title">Leading and Innovating in EU and USA Research</h4>
                                                    <p> Leading and innovating in research grants in both the European Union (EU) and the United States (USA) involves staying ahead of the curve in terms of emerging technologies and scientific discoveries. This requires a comprehensive understanding of the funding landscape, as well as a keen awareness of emerging trends and areas of interest within the scientific community. </p>
                                                    <p> Successful grant applicants must also demonstrate a clear and compelling research proposal, highlighting the potential impact of their work on society and the broader scientific community. In both the EU and USA, the competition for research grants is fierce, and funding agencies are increasingly seeking out innovative, interdisciplinary research that addresses pressing social and scientific challenges. As such, staying at the forefront of research innovation is crucial for securing funding and leading the way in scientific discovery.</p>
                                                   
                                                    
                                                </div>
                                            </div>
                                        </div>
                                        {/* End Single Area */}

                                       

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Page Wrapper */}
                
                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}
                

            </React.Fragment>
        )
    }
}
export default ServiceGrants;